exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-archive-2014-js": () => import("./../../../src/templates/archive2014.js" /* webpackChunkName: "component---src-templates-archive-2014-js" */),
  "component---src-templates-archive-2015-js": () => import("./../../../src/templates/archive2015.js" /* webpackChunkName: "component---src-templates-archive-2015-js" */),
  "component---src-templates-archive-2016-js": () => import("./../../../src/templates/archive2016.js" /* webpackChunkName: "component---src-templates-archive-2016-js" */),
  "component---src-templates-archive-2017-js": () => import("./../../../src/templates/archive2017.js" /* webpackChunkName: "component---src-templates-archive-2017-js" */),
  "component---src-templates-archive-2018-js": () => import("./../../../src/templates/archive2018.js" /* webpackChunkName: "component---src-templates-archive-2018-js" */),
  "component---src-templates-archive-2019-js": () => import("./../../../src/templates/archive2019.js" /* webpackChunkName: "component---src-templates-archive-2019-js" */),
  "component---src-templates-archive-2020-js": () => import("./../../../src/templates/archive2020.js" /* webpackChunkName: "component---src-templates-archive-2020-js" */),
  "component---src-templates-archive-2021-js": () => import("./../../../src/templates/archive2021.js" /* webpackChunkName: "component---src-templates-archive-2021-js" */),
  "component---src-templates-socio-js": () => import("./../../../src/templates/socio.js" /* webpackChunkName: "component---src-templates-socio-js" */)
}

